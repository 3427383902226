import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from "@mui/icons-material/Language";
import i18next from "i18next";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
const languages = [
  {
    code: "fr",
    name: "Français",
    country_code: "fr",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "hi",
    name: "हिंदी",
    country_code: "in",
  },
];

function LanguagesMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageName, setLanguageName] = useState(
    Cookies.get("i18next") || "fr"
  );
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (code) => {
    i18next.changeLanguage(code);
    setLanguageName(code);
  };

  useEffect(() => {
    document.title =
      Cookies.get("i18next") === "en" || Cookies.get("i18next") === "hi"
        ? "D Agency"
        : "Agence D";
  });
  return (
    <>
      <Button
        id="languages-button"
        startIcon={<LanguageIcon />}
        aria-controls={open ? "languages-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          color: "light.main",
          fontSize: { xs: "10px", md: "12px" },
          ":hover": {
            color: "link.hover",
            backgroundColor: "unset",
          },
        }}
      >
        {languageName}
      </Button>
      <Menu
        id="languages-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        keepMounted
        MenuListProps={{
          "aria-labelledby": "languages-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {languages.map(({ code, name, country_code }) => (
          <MenuItem
            onClick={() => changeLanguage(code, name)}
            key={code}
            sx={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <span className={`fi fi-${country_code}`}></span>
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
export default LanguagesMenu;
