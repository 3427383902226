import api from "../Environment.js";

/**
 * Get
 * @param {JSON} params - JSON
 * @param {string} route - string
 * @param {string} type - string (optional)
 * @param  key - (optional)
 * @return {JSON} res - JSON
 */
async function Get({ params, route, type = `json`, key }) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": `application/${type}` },
  };
  if (sessionStorage?.token) {
    requestOptions.headers.Authorization = `Bearer ${sessionStorage?.token}`;
  }

  const res = await fetch(
    `${api}${route}${key ? `/${key}` : ""}?${new URLSearchParams(params)}`,
    requestOptions
  );

  if (!res.ok) {
    const errorMessage = await res.json();
    throw new Error(errorMessage.message || "Failed to perform query");
  }
  const contentType = res.headers.get("content-type");
  const file = contentType.startsWith("image/");
  return file ? res.blob() : res.json();
}
export default Get;
