import React from 'react'
import { Box} from '@mui/material';

const BannerImage = (src, alt) => {
  return (
     <Box
            component="img"
            sx={{
                minWidth: "40ch",
                maxHeight: { xs: 453, md: 800 },
                maxWidth: { xs: "100%", md: "100%" },
                display: { xs: "none", sm: "block" },
                marginLeft: "-2em",
                overflow: "hidden",
            }}
            alt={alt}
            src={src}
        />
  )
 
}

export default BannerImage