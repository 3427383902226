import { Box, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../components/theme/theme";
import SocialMedia from "../../blocks/SocialMedia/SocialMedia";
import ContactFormBlock from "../../blocks/ContactFormBlock/ContactFormBlock";
import Introduction from "../../blocks/Introduction/Introduction";

export default function ContactPage() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{
          backgroundColor: "#FFFFFF",
          pt: 8,
          flexGrow: 1,
        }}
      >
        <Introduction page = 'contact'/>
        <SocialMedia />
        <ContactFormBlock />
      </Box>
    </ThemeProvider>
  );
}