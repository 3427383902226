import { Box, Grid, Link, Typography, CssBaseline, Icon } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import { motion } from "framer-motion";
import theme from "../../components/theme/theme";
import SocialMedias from '../../components/SocialMedias/SocialMedias';
import "./SocialMedia.css"


export default function SocialMedia() {

    const socialsData = SocialMedias(); // <--- importing social media data - object - from SocialMedias.js which imported it from the DB
    const socialMediaIconMotion = {
        initial: { rotate: 0, scale: 1, duration: 4000  },
        animate: { rotate: 10, scale: 1.3, duration: 4000 },
      }
      const socialMediaNameMotion = {
        initial: { color: '#0b1847', scale: 1 },
        animate: { color: "#082CAF", scale: 1.2 , duration: 2000},
      }
    
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box py={4} sx={{display:"flex", justifyContent:"center" }}  >
                <Grid container spacing={2} justifyContent="center" maxWidth="lg"  flexDirection={{xs:"column", sm:"row"}}>

                    {socialsData.map((socialLink) => (

                        socialLink.link && (

                            <Grid item lg key={socialLink.id}>
                                <Box
                                    component={motion.div}
                                    initial="initial"
                                    animate="initial"
                                    whileHover="animate"
                                    color="primary.main"
                                    className="card__clipped"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                     
                                        }}
                                >
                                    <Icon
                                        className="icon"
                                        component={motion.span}
                                        variants={socialMediaIconMotion}
                                        sx={{ mb: 1, fontSize: {xs:'3rem', sm:'4rem'}, height: 'unset' }}
                                    >
                                        {socialLink.icon}
                                    </Icon>
                                    <Typography variant="body1"  className="typography" component={motion.p}  variants={socialMediaNameMotion}  >
                                        <Link href={socialLink.link}  underline="none" target="_blank" component={motion.a}  variants={socialMediaNameMotion}>
                                            {socialLink.name}
                                        </Link>
                                    </Typography>
                                </Box>
                            </Grid>
                        )
                    ))}

                </Grid>
            </Box>
        </ThemeProvider>
    )
}