const LOGIN = "auth/login";
const LOGOUT = "auth/logout";
const USERS = "users";
const TRANSLATIONS = "translations";
export const SERVICES = "services";
export const SKILLS = "skills";
export const SETTINGS = "settings";
export const EMAIL = "email";
export default LOGIN;
export { USERS, LOGOUT, TRANSLATIONS };
