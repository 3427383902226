import validator from "validator";

/*https://www.npmjs.com/package/validator*/

export const isEmail = (email) => {
  return validator.isEmpty(email) ? true : validator.isEmail(email);
};

export const isEmpty = (text) => {
  return text ? validator.isEmpty(text, { ignore_whitespace: true }) : true;
};

export const isCurrency = (text) => {
  return validator.isCurrency(text, { allow_negatives: false });
};

export const isMobilePhone = (text) => {
  return validator.isMobilePhone(text, ["en-CA"]);
};
