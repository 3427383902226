import { Box, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../components/theme/theme";
import Introduction from "../../blocks/Introduction/Introduction";
import Service from "../../blocks/Service/Service";
import { SERVICES } from "../../util/Routes/Routes.js";
import Get from "../../util/Request/Get.js";
import { useQuery } from "@tanstack/react-query";
import { messageError } from "../../util/Toast.js";

export default function EmployerPage() {

  const getActiveServices = () =>
    Get({
      route: `${SERVICES}/getActiveServices`,
      key: "4",/* 4 = employer */
    });

  const { data: active_services } = useQuery({
    queryKey: ["4"],/* 4 = employer */
    queryFn: getActiveServices,
    onError: (error) => {
      messageError();
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          pt: 8,
          flexGrow: 1,
        }}
      >
        <Introduction page="employer" data={active_services} />
        {active_services?.map((service, index) => {
          return <Service key={index} index={index} serviceId={service.id} skillsIds={service.skills_ids} />;
        })}
      </Box>
    </ThemeProvider>
  );
}
