import React, { useEffect, useCallback, useState } from "react";
import Get from "../../util/Request/Get";
import { useQuery } from "@tanstack/react-query";
import { messageError } from "../../util/Toast";
import { SETTINGS } from "../../util/Routes/Routes";
import { FaLinkedin, FaFacebook, FaTelegram, FaWhatsapp } from "react-icons/fa";

const SocialMedias = () => {
  const getSettingsByType = (type) =>
    Get({
      route: `${SETTINGS}/getSettingsByType`,
      key: "social_media",
    });

  const { data: result, isLoading } = useQuery({
    queryKey: ["settings-social_media"],
    queryFn: getSettingsByType,
    onError: (error) => {
      messageError();
    },
  });

  const data = result?.list;

  const getIndex = useCallback(
    (db_label) => {
      const socialMedia = data?.find(
        (social_media) => social_media.label === db_label
      );
      return socialMedia ? socialMedia.value : "";
    },
    [data]
  );

  const [socialsData, setSocialsData] = useState([]);

  useEffect(() => {
    if (!isLoading && data) {
      const linkedInLink = getIndex("linkedin_link");
      const facebookLink = getIndex("facebook_link");
      const whatsappNumber = getIndex("whatsapp_number");
      const telegramNumber = getIndex("telegram_link");

      // Use the values (facebook, whatsapp, telegram) as variables here
      const newSocialsData = [
        {
          id: 1,
          name: "LinkedIn",
          link: linkedInLink ? `https://${linkedInLink}` : "",
          icon: <FaLinkedin />,
        },
        {
          id: 2,
          name: "Facebook",
          link: facebookLink ? `https://${facebookLink}` : "",
          icon: <FaFacebook />,
        },
        {
          id: 3,
          name: "Telegram",
          link: telegramNumber ? `https://t.me/${telegramNumber}` : "",
          icon: <FaTelegram />,
        },
        {
          id: 4,
          name: "WhatsApp",
          link: whatsappNumber ? `https://wa.me/1${whatsappNumber}` : "",
          icon: <FaWhatsapp />,
        },
      ];

      setSocialsData(newSocialsData);
    }
  }, [isLoading, data, getIndex]);

  return socialsData;
};

export default SocialMedias;
