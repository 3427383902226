import React from "react";
import MaskedInput from 'react-text-mask';

export const TextMaskCustom = React.forwardRef((props, ref) => {
    return (
      <MaskedInput
        {...props}
        ref={(inputRef) => {
          if (ref) {
            ref.current = inputRef ? inputRef.inputElement : null;
          }
        }}
        mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}    

      />
    );
  });