import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import AboutPage from "./pages/AboutPage/AboutPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import WorkerPage from "./pages/WorkerPage/WorkerPage";
import EmployerPage from "./pages/EmployerPage/EmployerPage";
import Header from "./blocks/Header/Header";
import Footer from "./blocks/Footer/Footer";
import { Container, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/theme/theme";
import ScrollToTopButton from "./components/ScrollToTopButton/ScrollToTopButton";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/* https://www.robinwieruch.de/react-router-example/ */

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="false" disableGutters sx={{ bgcolor: "#ECEFF1" }}>
        <Header />
        <main style={{}}>
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="about_us" element={<AboutPage />} />
            <Route path="worker" element={<WorkerPage />} />
            <Route path="contact_us" element={<ContactPage />} />
            <Route path="employer" element={<EmployerPage />} />
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Routes>
          <ToastContainer
            position="bottom-right"
            autoClose={1500}
            theme="colored"
          />
        </main>
        <ScrollToTopButton />
      </Container>
      <Footer />
    </ThemeProvider>
  );
}
export default App;
