import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import { Typography, Grid, CssBaseline, Container } from '@mui/material';
import theme from "../theme/theme";
import ConstructionCone from "../../images/ConstructionCone.png"

export default function Warning() {
    const { t } = useTranslation();
    const showWarning = t("block.warning.active")
    if (showWarning === "1") {
        return (
            <>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Container maxWidth="false" py={9} sx={{ backgroundColor: "light.main", paddingTop: { xs: ' 5rem', md: "10rem" }, paddingBottom: { xs: "1rem", md: "3rem" } }}>

                        <Container style={{ display: 'flex', alignItems: 'center', padding: '16px', justifyContent: "center" }} maxWidth="lg" >
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <img src={ConstructionCone} alt="notification" style={{ width: 58, height: "100%" }} />
                                </Grid>
                                <Grid item >
                                    <Typography variant="h3" component="h2" gutterBottom sx={{ textTransform: "capitalize" }}>
                                        {t("block.warning.title")}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {t("block.warning.description")}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Container>

                    </Container>
                </ThemeProvider>

            </>
        );
    }
}