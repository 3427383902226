import React, { createContext, useState, useEffect, useContext } from "react";
import Post from "../util/Request/Post";
import Get from "../util/Request/Get";
import LOGIN, { LOGOUT, USERS } from "../util/Routes/Routes";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { messageError } from "../util/Toast";
const UserContext = createContext({});

export function UserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const navigate = useNavigate();

  const loginRequest = (email, password) =>
    Post({ body: { email, password }, route: LOGIN });

  const { mutate: loginMutate, error: loginError } = useMutation({
    mutationFn: ({ email, password }) => loginRequest(email, password),
    onSuccess: (data) => {
      setCurrentUser(data.user);
      sessionStorage.token = data.token;
      navigate("/dashboard");
    },
    onError: (error, variables, context) => {
      error.message === "Failed to fetch" && messageError();
    },
  });
  const login = (email, password) => loginMutate({ email, password });

  const logoutRequest = () => Post({ route: LOGOUT });

  const { mutate: logoutMutate } = useMutation({
    mutationFn: logoutRequest,
    onSettled: () => sessionStorage.removeItem("token"),
  });

  const logout = () => {
    if (sessionStorage.token) {
      logoutMutate();
    }

    setCurrentUser();
    navigate("/");
  };

  const getByToken = () => Get({ route: `${USERS}/getByToken` });
  const { refetch } = useQuery({
    queryKey: ["user"],
    queryFn: getByToken,
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setCurrentUser(data.user);
    },
    onError: (error) => {
      logout();
      messageError(error.message);
    },
  });

  useEffect(() => {
    if (sessionStorage.token) {
      refetch();
    }
  }, [refetch]);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        login,
        logout,
        loginError,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

//Hook
function useUser() {
  return useContext(UserContext);
}

export default useUser;
