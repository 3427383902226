
import * as React from "react";
import { motion} from "framer-motion";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import { Typography, CssBaseline, Box, Button, Container, Stack,IconButton } from '@mui/material';
import theme from "../../components/theme/theme";
import bannerImage from '../../images/Rotated-Logo.svg';
import { useNavigate } from "react-router";
import './ContactUs.css'
// import splitTitle from "../../util/Traits/SplitTitle"; // <------- code used for the accent Word -- will stay commented undill decide if Hindi translation for accent word is correct
// import AccentWord from "../../util/Traits/AccentWord";// <------- code used for the accent Word -- will stay commented undill decide if Hindi translation for accent word is correct
import SocialMedias from '../../components/SocialMedias/SocialMedias';
import BannerImage from "../../components/BannerImage/BannerImage";





export default function ContactUs() {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const handleNavigation = (item) => {
        navigate(`/${item === "home" ? "" : item}`);
    };

  //  ========  Both lines are Commented untill decide if the translation of the Accent Word is accurate in Hindi - The accent word will have a border around =======

   // const [titleWords] = splitTitle(t("block.contact_us.title"))
   // const modifiedTitle = AccentWord(titleWords)

 const socialsData = SocialMedias();

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container
                    maxWidth="false"
                    disableGutters

                    sx={{
                        position: 'relative',
                        color: 'light.main',
                        minHeight: 500,
                        background: `${theme.palette.gradient.main}`,
                        paddingTop: { xs: 9, sm: 9, md: 11 },
                    }}

                >
                    <Container maxWidth="lg"
                        component={motion.div}
                        whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
                        transition={{ duration: 0.5 }}
                    >
                        <Box sx={{ padding: "3rem 0" }}>

                            <Stack
                                spacing={{ xs: 1, sm: 5 }}
                                direction="column"
                                flexWrap="wrap"
                                justifyContent="space-between"
                                alignItems="stretch"
                                sx={{
                                    flexGrow: 2,
                                    gap: { xs: 3, sm: 2 }
                                }}
                            >

                                <Stack
                                    sx={{
                                        height: "100%",
                                    }}
                                    direction="row"
                                    alignItems="stretch"
                                >

                                    <Stack sx={{
                                        flexGrow: 1,
                                    }}
                                        justifyContent="center"
                                        alignItems={{ xs: 'center', sm: 'flex-start', }}
                                        gap={2}>
                                        <Typography component="h1"
                                            variant='h1'
                                            color="light.main"

                                            sx={{
                                                textTransform: 'capitalize',
                                                fontSize: { xs: "2.5rem", sm: "3.4rem", md: "3.75rem" },
                                                fontWeight: 'bold',
                                                textAlign: { xs: "center", sm: "left" },
                                                transform:"rotate"
                                            }}

                                            
                                            // dangerouslySetInnerHTML={{ __html: modifiedTitle }} // <----- Commented untill decide if the translation of the Accent Word is accurate in Hindi - The accent word will have a border around

                                            dangerouslySetInnerHTML={{ __html: `${t("block.contact_us.title")} ` }}

                                        />

                                        <Typography
                                            component="p"
                                            color="light.main"
                                            sx={{
                                                fontSize: { xs: ".9rem", sm: "1rem", md: "1.2rem" },
                                                textAlign: { xs: "center", sm: "left" }
                                            }}
                                        >
                                            {t("block.contact_us.description")}
                                        </Typography>

                                        <Box sx={{ mt: 2, color: "light.main" }} className="header__btn">

                                            <Button
                                                component={motion.button}
                                                whileHover={{ 
                                                    transform: 'translateY(-0.2rem)' }}
                                                animate={{ 
                                                    opacity: 1, 
                                                    duration: 2000, }}
                                                variant="contained"
                                                size="large"
                                                className="btn accent"
                                                color="accent"
                                                sx={{ border: "1px solid #c14323",
                                                '&:hover': {
                                                    borderColor: '#FFFFFF',
                                                  },
                                                }}
                                                onClick={() => { handleNavigation("contact_us"); }}
                                            >
                                                {t("shared.btn.contact_us")}
                                            </Button>
                                        </Box>
                                    </Stack>

                                    <Box
                                        className='header__profile'
                                        flexGrow={1}
                                        component={motion.div}
                                        whileInView={{ scale: [0.7, 1] }}
                                        transition={{ duration: 1, ease: 'easeInOut' }}

                                        sx={{
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            flexBasis: "40%",

                                            display: { xs: "none", sm: "flex" },
                                        }}>

                                        {BannerImage(bannerImage, "Agence D logo image")}

                                    </Box>

                                </Stack>

                               
                                <Stack flexGrow={2} direction='row' alignItems="center" justifyContent="center" gap={4} spacing={2} className="header__socials">

                                    {socialsData.map((socialLink) => (
                                        socialLink.link && (
                                        <IconButton
                                            key={socialLink.id}
                                            component={motion.a}
                                            whileHover={{ transform: 'translateY(-0.2rem)' }}
                                            animate={{ opacity: 1, duration: 2000, }}
                                            href={socialLink.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            underline="none"
                                            color="light.main"
                                            aria-label={`${socialLink.name} " icon link"`}
                                            ml={0}
                                            sx={{ 
                                                fontSize: { 
                                                xs: "1.8rem", 
                                                sm: "2rem",
                                                md: "2.3rem"} ,
                                                color: 'link.primary',
                                            }}
                                        >
                                            {socialLink.icon}

                                        </IconButton>
                                        )
                                    ))}

                                </Stack> 

                            </Stack>
                        </Box>
                    </Container>
                </Container>

            </ThemeProvider >

        </>
    );
}
