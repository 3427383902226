import { createTheme } from "@mui/material";
import styles from "./styles";


const theme = createTheme({

    palette: {
        primary: {
            main: '#0b1847',
        },

        secondary: {
            main: '#4c66dc',
        },

        light: {
            main: '#ffffff'
        },
        grey:{
            main:'#f8fafe', 
            dark:'#e9ecff',
            light:'#f9f9f9'
        },

        text: {
            primary: '#0e1b3c',
            secondary: '#0d2154',
            links: '#185ce3'
        },

        textPrimary: {
            main: '#152844'
        },

        textSecondary: {
            main: '#234fb1',
            dark: '#282a37',
            
        },

        accent: {
            main: '#c45037',
            light: '#ea5e40',
            dark: '#dc3a11',

        },
        link: {
            primary: '#FFFFFF',
            secondary: "#0066ff",
            '&:hover': {
                color: '#f1896e',

            },
            hover: '#f1896e'
        },

        gradient: {
            main: 'linear-gradient(90deg, #000000, #082CAF)',
            vertical:'linear-gradient(to bottom, #7ac4ff, #4c66dc)'
        },
        boxShadow:{
            main:'0 0 5px rgba(33,33,33,.2)',
            dark:'0 0 9px rgba(33,33,33,.3)'
        }

    },

    typography: {
//https://mui.com/material-ui/customization/typography/
        fontFamily: 'Roboto, sans-serif', // Default font family

        h1: {
            fontFamily: 'Roboto Flex, sans-serif', // Font family for h1 variant
        },
        h2: {
            fontFamily: 'Inter, sans-serif', // Font family for h2 variant
        },
        h4: {
            fontFamily: 'Inter, sans-serif', // Font family for h2 variant
        },
        heading: {
            fontFamily: 'Times, serif', // Font family for heading variant
        },
        subtitle1: {
            fontFamily: 'Helvetica', // Font family for subtitle1 variant
        },
        body: {
            fontFamily: 'Inter, sans-serif', // Font family for body variant
        },
        button: {
            // fontStyle: 'italic',
            fontFamily: 'Roboto Flex, sans-serif', // Font family for body variant
        },

    },

    overrides: {
        accentWord: styles.accentWord,

        MuiButton: {
            root: {
                borderWidth: 7,
                borderStyle: 'solid',
                borderRadius: 50,

            },
        },

    },



});

export default theme;