import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Get from "../../util/Request/Get.js";
import { useQuery } from "@tanstack/react-query";
import { messageError } from "../../util/Toast.js";
import { motion } from "framer-motion";
import { SKILLS } from "../../util/Routes/Routes";
import { backend } from "../../util/Environment.js";

export default function Skill({ skillId }) {

    const { t } = useTranslation();

    const getSkillById = () => Get({ route: `${SKILLS}/getById`, key: skillId });
    const {
        data: data_skill,
        refetch,
    } = useQuery({
        queryKey: ["skill", skillId],
        queryFn: getSkillById,
        enabled: false,

        onError: (error) => {
            messageError();
        },
    });

    useEffect(() => {
        if (skillId) {
            refetch();
        }
    }, [refetch, skillId]);

    return (
        <>
            <Container
                style={{
                    height: "200px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    padding: "16px",
                }}
                maxWidth="lg"
            >
                {/*
                    La condition ci-dessous vérifie si `backend` et `data_skill?.skill.url` ne sont pas null ou undefined.
                    Si les deux variables sont définies, l'image sera affichée.
                    Sinon, si l'une des variables est null ou undefined, l'image ne sera pas affichée.
                */}
                {backend && data_skill?.skill.url && (
                    <Box
                        component="img"
                        alt={t(data_skill?.translations.title.label)}
                        src={`${backend}${data_skill?.skill.url}`}
                        sx={{
                            width: "1em",
                            height: "100%",
                            maxWidth: { xs: 40, md: 100 },
                            minWidth: {
                                xs: "3rem",
                                md: "4rem",
                            },
                            objectFit: "contain",
                            flexGrow: 1,
                        }}
                    />
                )}

                <Typography
                    component={motion.h3}
                    whileInView={{
                        y: [100, 50, 0],
                        opacity: [0, 0, 1],
                    }}
                    transition={{ duration: 0.5 }}
                    color="textSecondary.main"
                    sx={{
                        fontSize: "1.2rem",
                        color: "black",
                        textTransform: "capitalize",
                        objectFit: "contain"
                    }}
                >
                    {t(data_skill?.translations.description.label)}
                </Typography>
            </Container>
        </>
    );
}
