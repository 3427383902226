import Nav from "./Nav/Nav.js";


function Header() {


  return (
    <>
    
      <Nav />
    </>
  )
}
export default Header;
