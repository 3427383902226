import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { isEmail, isMobilePhone, isEmpty } from "../../../util/Validation";
import { Email, Phone, Person, Message, Business } from "@mui/icons-material";
import { TextMaskCustom } from "../../../util/Formating";
import {
  Select,
  TextField,
  InputAdornment,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import POST from "../../../util/Request/Post";
import { useMutation } from "@tanstack/react-query";
import { messageSuccess, messageError } from "../../../util/Toast";
import { EMAIL } from "../../../util/Routes/Routes";

export default function ContactForm() {
  const { t } = useTranslation();
  const [isRoleCompany, setIsRoleCompany] = useState(false);
  const [errors, setErrors] = useState({});

  const phoneInputRef = React.createRef();
  const [data, setData] = useState({
    role: "",
    employerName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const handleRoleChange = (e) => {
    setData((prevData) => ({ ...prevData, role: e.target.value }));

    if (e.target.value === "employer") {
      setIsRoleCompany(true);
    } else {
      setIsRoleCompany(false);
      delete errors.employerNameError;
      setData((prevData) => ({ ...prevData, employerName: "" }));
    }
  };
  const sendEmail = () =>
    POST({
      route: `${EMAIL}/sendRequestInformationMail`,
      body: {
        role: data.role,
        company: data.employerName,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        message: data.message,
        subject: t("block.contact_form.title"),
      },
    });
  const validation = (name, value) => {
    const text = value === undefined ? data[name] : value;
    if (isEmpty(text) && name === "role") {
      errors[`${name}Error`] = t("shared.term.select_value");
    } else if (isEmpty(text) && name !== "phone") {
      errors[`${name}Error`] = t("shared.term.required");
    } else if (
      (!isEmail(text) && name === "email") ||
      (!isMobilePhone(text) && !isEmpty(text) && name === "phone")
    ) {
      errors[`${name}Error`] = t("shared.term.invalid");
    } else {
      delete errors[`${name}Error`];
    }

    setErrors({ ...errors });
  };
  const mutation = useMutation({
    mutationFn: sendEmail,
    onSuccess: (data) => {
      messageSuccess("email sent");

      //reset form after sending
      setData((prevData) => {
        const updatedData = {};

        for (const key in prevData) {
          key !== "role"
            ? (updatedData[key] = "")
            : (updatedData[key] = prevData[key]);
        }
        return updatedData;
      });
    },
    onError: (error, variables, context) => {
      messageError("failed to send email");
    },
  });

  // Display specific format of the phone number  input -- (XXX) XXX-XXXX
  const handlePhoneChange = (e) => {
    // Remove any non-digit characters from the input value
    const formattedValue = e.target.value.replace(/\D/g, "");

    setData((prevData) => ({
      ...prevData,
      phone: formattedValue,
    })); // sending to the db phone format 5144111411
  };

  function handleSubmit(event) {
    event.preventDefault();
    Object.keys(data).forEach((name) => {
      if (name !== "employerName") validation(name);
    });

    if (data.role === "employer") {
      validation("employerName");
    }

    //pass validation
    if (!Object.values(errors).length) {
      mutation.mutate();
    }
  }

  return (
    <Grid
      maxWidth={{ md: "sm", lg: "md" }}
      container
      spacing={1}
      direction="column"
      m="auto"
      py="2rem"
    >
      <Box component="form" onSubmit={handleSubmit}>
        <FormControl
          variant="filled"
          sx={{
            display: "flex",
            m: 1,
            "& .MuiInputBase-input ": {
              backgroundColor: "white",
              borderRadius: "4px 4px 0px 0px ",
            },
          }}
        >
          <InputLabel id="role-select-label">
            {t("block.contact_form.role_label")}
          </InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={data.role}
            label="Role"
            variant="filled"
            onChange={handleRoleChange}
            fullWidth
            error={!!errors.roleError}
          >
            <MenuItem value="none" disabled>
              {t("shared.term.select_value")}
            </MenuItem>
            <MenuItem value="worker">{t("shared.term.worker")}</MenuItem>
            <MenuItem value="employer">{t("shared.term.employer")}</MenuItem>
          </Select>
          {errors.roleError ? (
            <FormHelperText error={!!errors.roleError}>
              {errors.roleError}
            </FormHelperText>
          ) : null}
        </FormControl>

        <FormControl
          sx={{
            m: 1,
            "& .MuiTextField-root input": {
              background: "white",
              borderRadius: "4px",
            },
          }}
        >
          <Grid container spacing={2}>
            {isRoleCompany && (
              <>
                <Grid xs={12}>
                  <TextField
                    type="text"
                    variant="filled"
                    onChange={(e) => {
                      setData((prevData) => ({
                        ...prevData,
                        employerName: e.target.value,
                      }));
                      validation("employerName", e.target.value);
                    }}
                    color="primary"
                    label={t("block.contact_form.company_name")}
                    value={data.employerName}
                    fullWidth
                    helperText={errors.employerNameError}
                    error={!!errors.employerNameError}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Business />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}
            <>
              <Grid xs={12} sm={6}>
                <TextField
                  type="text"
                  variant="filled"
                  color="primary"
                  label={t("block.contact_form.first_name")}
                  onChange={(e) => {
                    setData((prevData) => ({
                      ...prevData,
                      firstName: e.target.value,
                    }));
                    validation("firstName", e.target.value);
                  }}
                  value={data.firstName}
                  fullWidth
                  helperText={errors.firstNameError}
                  error={!!errors.firstNameError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid xs={12} sm={6}>
                <TextField
                  type="text"
                  variant="filled"
                  color="primary"
                  label={t("block.contact_form.last_name")}
                  onChange={(e) => {
                    setData((prevData) => ({
                      ...prevData,
                      lastName: e.target.value,
                    }));
                    validation("lastName", e.target.value);
                  }}
                  value={data.lastName}
                  fullWidth
                  helperText={errors.lastNameError}
                  error={!!errors.lastNameError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>

            <Grid xs={12} sm={6}>
              <TextField
                type="email"
                variant="filled"
                color="primary"
                placeholder="abc@gmail.ca"
                label={t("block.contact_form.email")}
                onChange={(e) => {
                  setData((prevData) => ({
                    ...prevData,
                    email: e.target.value,
                  }));
                  validation("email", e.target.value);
                }}
                value={data.email}
                fullWidth
                helperText={errors.emailError}
                error={!!errors.emailError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid xs={12} sm={6}>
              <TextField
                type="tel"
                variant="filled"
                color="primary"
                placeholder="(___) ___-____"
                label={t("block.contact_form.phone")}
                helperText={errors.phoneError}
                error={!!errors.phoneError}
                inputRef={phoneInputRef}
                onChange={(e) => {
                  validation("phone", e.target.value);
                  handlePhoneChange(e);
                }}
                value={data.phone}
                fullWidth
                InputProps={{
                  inputComponent: TextMaskCustom,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Phone />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid xs={12}>
              <TextField
                type="text"
                multiline
                rows={4}
                color="primary"
                label={t("block.contact_form.message")}
                onChange={(e) => {
                  setData((prevData) => ({
                    ...prevData,
                    message: e.target.value,
                  }));
                  validation("message", e.target.value);
                }}
                value={data.message}
                fullWidth
                error={!!errors.messageError}
                sx={{
                  backgroundColor: "white",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Message />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.messageError ? (
                <FormHelperText error={!!errors.messageError}>
                  {errors.messageError}
                </FormHelperText>
              ) : null}
            </Grid>

            <Grid xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ borderRadius: "50px", padding: "5px 25px" }}
              >
                {t("shared.btn.submit")}
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </Box>
    </Grid>
  );
}
