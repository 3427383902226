import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Box, Container, CssBaseline, Grid, Typography, } from '@mui/material';
//waiting for about us page
/* import {  Button } from '@mui/material'; */
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../components/theme/theme";
import abtUsImg from '../../images/OfficeWorkers.jpg';
import "./AboutUs.css";
import splitTitle from "../../util/Traits/SplitTitle";

export default function AboutUs() {
    const { t } = useTranslation();
    const [titleWords, lastWord] = splitTitle(t("block.about_us.title"))

    return (

        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container maxWidth="lg">
                <Grid container spacing={2} alignItems="stretch" py={8} sx={{ flexDirection: { xs: "column-reverse", sm: "row" } }} >

                    <Grid item xs={12} md={6}>
                        <Typography variant="h3"                            
                             component={motion.h2}
                             whileInView={{ y: [100, -50, 0], opacity: [0, 0, 1] }}
                             transition={{ duration: 0.8 }}
                             viewport={{ once: true }}
                            gutterBottom
                            color={theme.palette.textPrimary.main}
                            sx={{
                                fontWeight: "bold",
                                "& span:last-child": {
                                    color: "secondary.main",
                                },
                                textTransform: "capitalize",
                            }}

                        >
                            {titleWords.join(" ")} <span>{lastWord}</span>

                        </Typography>
                        <Typography variant="body1"
                                    mb={3} 
                                    component={motion.p}
                                    initial={{ x: 0, opacity: 0 }}  // Default state for when not in view
                                    whileInView={{ x: [100, -50, 0], opacity: [0, 0, 1] }}
                                    transition={{ duration: 0.8 }} 
                                    viewport={{ once: true }}
                                    gutterBottom >
                            {t("block.about_us.description")}
                            
                        </Typography>

         {/* =================   *******  Code - button-  commented until the page about us is ready   ******    ====================== */}

                        {/* <Button variant="outlined" color="accent" component={motion.button}
                            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.3 }}>
                            Read More
                        </Button> */}

                    </Grid>
                    <Grid item xs={12} sm={4} md={6} margin='auto'>

                        <Box
                            className="bkg__clipped"
                            component={motion.img}
                            mb={3} 
                            initial={{ sm:{x: [0, 0, 0]},x: [0, 0, 0], opacity: 0 }}  // Default state for when not in view
                            whileInView={{ sm:{x: [100, 50, 0]}, opacity: [0, 0, 1] }}
                            transition={{ duration: 0.8 }} 
                            viewport={{ once: true }}
                            exit={{ opacity: 0 }} 
                            overflow='hidden'
                            alt="office workers"
                            src={abtUsImg}
                            sx={{
                                minWidth: "300px",
                                maxHeight: { xs: 453, md: 700 },
                                maxWidth: { xs: "100%", md: "100%" },

                            }}
                        />
                       

                    </Grid>

                </Grid>
            </Container>
        </ThemeProvider>


    );
}