
/**
 * @param {String} title
 */

export default function splitTitle( title){
    const blockTitle = title;
    const titleWords = blockTitle.split(" ");
    const lastWord = titleWords.pop();
 return[
    titleWords, lastWord
 ]
}