import api from "../Environment.js";

/**
 * Post
 * @param {JSON} body - JSON
 * @param {string} route - string
 * @param {string} type - string (optional)
 * @return {JSON} res - JSON
 */
async function Post({ body, route, type = `json` }) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": `application/${type}` },
    body: JSON.stringify(body),
  };
  if (sessionStorage?.token) {
    requestOptions.headers.Authorization = `Bearer ${sessionStorage?.token}`;
  }

  const res = await fetch(`${api}${route}`, requestOptions);
  if (!res.ok) {
    const errorMessage = await res.json();
    throw new Error(errorMessage.message || "Failed to perform mutation");
  }
  return res.json();
}
export default Post;
