import React, { createContext, useState, useEffect, useContext } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useIsMutating } from "@tanstack/react-query";
const LoaderContext = createContext({});

export function LoaderProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const IsMutating = useIsMutating();

  useEffect(() => {
    IsMutating > 0 ? setLoading(true) : setLoading(false);
  }, [IsMutating]);
  return (
    <LoaderContext.Provider
      value={{
        setLoading,
      }}
    >
      {loader(loading)}
      {children}
    </LoaderContext.Provider>
  );
}

const loader = (loading) => (
  <div>
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  </div>
);

//Hook
function useLoader() {
  return useContext(LoaderContext);
}

export default useLoader;
export { loader };
