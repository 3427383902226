import * as React from "react";
import { useEffect } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Container,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import theme from "../../components/theme/theme";
import "./Service.css";
import { SERVICES } from "../../util/Routes/Routes.js";

import Get from "../../util/Request/Get.js";
import { useQuery } from "@tanstack/react-query";
import { messageError } from "../../util/Toast.js";
import Skill from "../../components/Skill/skill";

export default function Service({ index, serviceId, skillsIds }) {

  const { t } = useTranslation();

  const getServiceById = () => Get({ route: `${SERVICES}/getById`, key: serviceId });
  const {
    data: service,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["service", serviceId],
    queryFn: getServiceById,
    enabled: false,

    onError: (error) => {
      messageError();
    },
  });

  useEffect(() => {
    if (serviceId) {
      refetch();
    }
  }, [refetch, serviceId]);




  const splittedQuotes = t(service?.translations?.quote?.label).split("**author**");


  const splittedParagraphs =
    t(service?.translations?.description.label).split("**new paragraph**");



  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          id={service?.translations.title.label}
          sx={{
            backgroundColor: index % 2 === 0 ? "#eff2f9" : "#FFFFF",
            py: 0,
            pt: index === 0 ? 5 : 8,
            flexGrow: 1,
          }}
        >
          {isFetching ? (
            <CircularProgress />
          ) : (
            <Container maxWidth="lg">
              <Box
                className={service?.translations.title}

                sx={{
                  textAlign: "center",
                  pt: index === 0 ? 5 : 8,
                  mb: 0,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {/* Zone titre */}
                <Typography
                  whileInView={{
                    y: [100, -50, 0],
                    opacity: [0, 0, 1],
                  }}
                  transition={{ duration: 0.5 }}
                  variant="h3"
                  component={motion.h2}
                  gutterBottom
                  color="textPrimary.main"
                  sx={{
                    fontWeight: "bold",
                    "& span:last-child": {
                      color: "secondary.main",
                    },
                    textTransform: "capitalize",
                  }}
                >
                  {t(service?.translations.title.label)}
                </Typography>
                {/* Zone citation */}
                {splittedQuotes[0].length > 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    {/* Zone Quotes and text*/}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}>
                      {/* Zone Quote left */}
                      <Box
                        component="span"
                        sx={{
                          height: "auto",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          padding: "1rem",
                        }}>
                        <FormatQuoteIcon className="quote1-content" sx={{ alignSelf: "start", fill: "4F4F4F" }} />

                      </Box>
                      {/* Zone Texte quote*/}
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          Width: "100%",
                          padding: "2rem 1rem",

                        }}>
                        <Typography
                          component={motion.h3}
                          whileInView={{
                            y: [100, 50, 0],
                            opacity: [0, 0, 1],
                          }}
                          transition={{ duration: 0.5 }}
                          color="textSecondary.main"
                          sx={{
                            width: "100%",
                            fontWeight: "bold",
                            fontSize: "1.5rem",
                            color: "black",

                          }}
                        >
                          {splittedQuotes[0]}

                        </Typography>
                      </Box>
                      {/* Zone Quote right */}
                      <Box
                        component="span"
                        sx={{
                          height: "auto",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "end",
                          padding: "1rem",
                        }}>
                        <FormatQuoteIcon className="quote1-content" sx={{ alignSelf: "end" }} />

                      </Box>
                    </Box>
                    {/* Zone Author */}
                    <Typography
                      component={motion.h3}
                      whileInView={{
                        y: [100, 50, 0],
                        opacity: [0, 0, 1],
                      }}
                      transition={{ duration: 0.5 }}
                      color="textSecondary.main"
                      sx={{

                        fontSize: "1.2rem",
                        color: "black",
                        paddingRight: "1rem",
                        fontStyle: "italic",
                        textAlign: "right"
                      }}
                    >
                      {splittedQuotes[1]}
                    </Typography>
                  </Box>
                )}
              </Box>
              {/* Zone text and icons */}
              <Grid
                container
                spacing={2}
                my={5}
                sx={{
                  flexDirection: {
                    xs: "column-reverse",
                    sm: "row",
                  },
                }}
              >
                {/* Zone Texte */}
                <Container
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "16px",
                    justifyContent: "center",
                  }}
                  maxWidth="lg"
                >
                  {/* Map with paragraphs */}
                  {splittedParagraphs?.map((paragraph, index) => (
                    <Typography
                      key={index}
                      variant="body1"
                      mb={3}
                      component={motion.p}
                      initial={{ x: 0, opacity: 0 }} // Default state for when not in view
                      whileInView={{
                        x: [100, -50, 0],
                        opacity: [0, 0, 1],
                      }}
                      transition={{ duration: 0.8 }}
                      viewport={{ once: true }}
                      gutterBottom
                    >
                      {paragraph}
                    </Typography>
                  ))}
                </Container>

                {/* Zone icon */}
                <Container
                  component={motion.div}
                  whileInView={{
                    y: [100, 50, 0],
                    opacity: [0, 0, 1],
                  }}
                  transition={{ duration: 0.5 }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                    justifyContent: "space-evenly",
                    padding: "16px",
                  }}
                  maxWidth="lg"
                >
                  {/* Map with icons */}
                  {skillsIds.map((skillId) => (
                    skillId !== 0 ? <Skill key={skillId} skillId={skillId} /> : null
                  ))}
                </Container>
              </Grid>
            </Container>
          )}
        </Box>
      </ThemeProvider>
    </>
  );
}
