import { useTranslation } from "react-i18next";
import { Box, Container, Typography } from "@mui/material";
import { motion } from "framer-motion";
import theme from "../../components/theme/theme";
import formImage from '../../images/eMail-logo-Transparent.svg'
import ContactForm from "../../components/Form/ContactForm/ContactForm";

const style = {
  leftBarForm:{
    background: `${theme.palette.grey.dark}`,
    position: 'relative',
    overflow: 'hidden',
    width: { xs: '100%', md: "244px" },      
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '2rem',
    gap:'2rem'},
  
  leftBarFormText:{
    color:"textPrimary.main",
    fontWeight: "bold",
    textAlign:"center",
    fontSize:"2rem",
    textTransform: 'capitalize',
  },
  leftBarFormImg:{
    maxWidth: "30ch",
    overflow:'hidden',
    marginBottom: '-50px'

  },
  formContainer:{
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
    flexDirection: 'row',
    backgroundColor: 'grey.main',
    borderRadius: "10px",
    overflow: 'hidden',
    padding: { xs: "0" }
  }

};

export default function ContactFormBlock() {
  const { t } = useTranslation();
  return (
    <Box style={{ padding: "3rem  1rem ", }}>
    
      <Container  
        component={motion.div}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }} 
        sx={style.formContainer} >

        <Box  sx={style.leftBarForm} >

          <Typography sx={style.leftBarFormText}>
            {t('block.contact_form.title')}
          </Typography>

          <Box
            component='img'
            alt="office workers"
            src={formImage}
            sx={style.leftBarFormImg}
          />
        </Box>

        <ContactForm />

      </Container>
    </Box>
  )
}