import { toast } from "react-toastify";

/* https://www.npmjs.com/package/react-toastify */

export const messageSuccess = (message = "Success") => {
  toast.success(message);
};

export const messageError = (message = "Error") => {
  toast.error(message);
};

export const messageInfo = (message = "Info") => {
  toast.info(message);
};

export const messageWarning = (message = "Warning") => {
  toast.warning(message);
};
