import { useTranslation } from "react-i18next";
import { motion } from "framer-motion"; // ------ https://www.framer.com/motion/

import ServiceCard from "../../components/ServiceCard/ServiceCard";
import { Box, Container, Grid, Typography, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../components/theme/theme";
import WorkersImage from "../../images/workers.svg";
import EmployersImage from "../../images/employers.svg";
import splitTitle from "../../util/Traits/SplitTitle";

const style = {
    sectionWrapper:{
      backgroundColor: "#FFFFFF",
      padding: "5rem 0",
      flexGrow: 1,
    },
    sectionTitle:{
      fontWeight: "bold",
      "& span:last-child": {
        color: "secondary.main",
      },
      textTransform: "capitalize",
    }
}

export default function OurServices() {
  const { t } = useTranslation();

  const [titleWords, lastWord] = splitTitle(t("block.our_services.title"))

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={style.sectionWrapper} >
        <Container
          maxWidth="lg"
        >
          <Box sx={{ textAlign: "center", mb: "4rem" }}>
            <Typography
              whileInView={{ y: [100, -50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 0.5 }}
              variant="h3"
              component={motion.h2}
              gutterBottom
              color="textPrimary.main"
              sx={style.sectionTitle}
            >
              {titleWords.join(" ")} <span>{lastWord}</span>
              
            </Typography>
            <Typography
              component={motion.p}
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 0.5 }}
              color="textSecondary.dark"
            >
              {t("block.our_services.description")}
            </Typography>
          </Box>

          <Grid
            component={motion.div}
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.6 }}
            container
            flexWrap={{ sm: "wrap", md: "nowrap" }}
            gap={3}
            sx={{ justifyContent: "space-between" }}
          >
            <ServiceCard
              beforeColor='accent.light'
              component={motion.div}
              src={WorkersImage}
              target={"worker"}
              transition={{ duration: 0.6, delay: 0.2 }}
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            />
            <ServiceCard
              component={motion.div}
              beforeColor='secondary.main'
              src={EmployersImage}
              target={"employer"}
              transition={{ duration: 0.6, delay: 0.2 }}
              whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            />
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
