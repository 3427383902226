import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { UserProvider } from "./context/User";
import "./util/i18n";

import { loader, LoaderProvider } from "./context/Loader";
const root = ReactDOM.createRoot(document.getElementById("root"));
// Initialze the client
const queryClient = new QueryClient();

root.render(
  <React.Suspense fallback={loader(true)}>
    <React.StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <LoaderProvider>
            <UserProvider>
              <App />
            </UserProvider>
          </LoaderProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  </React.Suspense>
);
