import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  List,
  Grid,
  ListItem,
  ListItemText,
  ThemeProvider,
  CssBaseline,
  CardActions,
  Button,
} from "@mui/material";
import { motion } from "framer-motion";
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded'
import theme from "../theme/theme";
import { useTranslation } from "react-i18next";
import { SERVICES } from "../../util/Routes/Routes";
import Get from "../../util/Request/Get";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { messageError } from "../../util/Toast";

const style = (beforeColor) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "30px 15px ",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      backgroundColor: beforeColor,
      top: "-1px",
      left: "-1px",
      width: "calc(100% + 2px)",
      height: "10px",
      borderRadius: "6px 6px 0 0",
    },
  },
  cardHeaderTitle: {
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: 400,
  },
  cardImg: {
    margin: "auto",
    maxHeight: "100%",
    maxWidth: "325px",
    height: "230px",
  },
  cardContent: {
    display: "flex",
    flex: "1",
    alignItems: "stretch",
  },
  contentWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  cardSubTitle: {
    fontWeight: 300,
    textAlign: "center",
    textTransform: "uppercase",
    color: "secondary.main",
  },
  cardListItemWrapper: {
    py: 0.5,
    gap: "20px",
    display: "flex",
    alignItems: "stretch",
  },
});

export default function ServiceCard({ src, beforeColor, target }) {
  const { t } = useTranslation();

  let target_id;
  switch (target) {
    case "worker":
      target_id = 3;
      break;

    case "employer":
      target_id = 4;
      break;
  }

  const getActiveServices = () =>
    Get({
      route: `${SERVICES}/getActiveServices`,
      key: target_id,
    });

  const { data: active_services } = useQuery({
    queryKey: [target_id],
    queryFn: getActiveServices,
    onError: (error) => {
      messageError();
    },
  });

  const navigate = useNavigate();
  const handleNavigation = (item) => {
    navigate(`/${item === "home" ? "" : item}`);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        md={6}
        my={2}
        mb={5}
        pl="unset"
        sx={{
          backgroundColor: "#fff",
          paddingLeft: { xs: "unset", md: "unset" },
        }}
      >
        <Card
          component={motion.div}
          whileInView={{
            opacity: 1,
            boxShadow: `${theme.palette.boxShadow.main}`,
          }}
          whileHover={{
            backgroundColor: `${theme.palette.grey.light}`,
            boxShadow: `${theme.palette.boxShadow.dark}`,
          }}
          transition={{ duration: 0.3 }}
          sx={style(beforeColor).card}
        >
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            sx={style(beforeColor).cardHeaderTitle}
          >
            {t(`shared.term.${target}`)}
          </Typography>

          <CardMedia
            component="img"
            src={src}
            alt={`image of ${target}s`}
            sx={style(beforeColor).cardImg}
          />

          <CardContent sx={style(beforeColor).cardContent}>
            <div style={style(beforeColor).contentWrapper}>
              <Typography
                gutterBottom
                variant="h5"
                sx={style(beforeColor).cardSubTitle}
              >
                {t(`block.our_services.${target}s.title`)}
              </Typography>

              <List>
                {active_services?.map((service) =>
                  service.front_page ? (
                    <ListItem
                      key={service.id}
                      sx={style(beforeColor).cardListItemWrapper}
                    >
                      <ListItemText
                        disableTypography
                        variant="body"
                        primary={
                          <Typography
                            variant="body1"
                            style={{ fontSize: "1.3rem", textAlign: "center" }}
                          >
                            {t(`service.${target}.${service.id}.title`)}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ) : null
                )}
              </List>
            </div>
          </CardContent>
          <CardActions
            disableSpacing
            sx={{ justifyContent: 'center' }}
          >

            <Button
              component={motion.button}
              variant='outlined'
              color="secondary"
              whileHover={{
                color: "primary",
                '& .MuiButton-label span': { color: "red" },
                scale: 1.01,
                transition: { duration: 0.1 },
              }}
              whileTap={{ scale: 0.98 }}
              endIcon={<DoubleArrowRoundedIcon />}
              sx={{
                borderRadius: "50px",
              }}
              onClick={() => { handleNavigation(target); }}

            >
              {t(`block.our_services.${target}s.btn_content`)}
            </Button>

          </CardActions>
        </Card>
      </Grid>
    </ThemeProvider>
  );
}
