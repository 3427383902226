import ContactUs from "../../blocks/ContactUs/ContactUs.js";
import OurServices from "../../blocks/OurServices/OurServices.js";
import AboutUs from "../../blocks/AboutUs/AboutUs.js";
import Warning from "../../components/Warning/Warning.js";

export default function HomePage() {

  return (
    <>
      <Warning />
      <ContactUs />   
      <OurServices />
      <AboutUs />
    </>
  );
}